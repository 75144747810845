.about-sec{
    min-height: 100vh;
    background-image: url('../../assets/images/webps/about-section-bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.about-image-container, .about-content-container{
    width: 90%;
    min-height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
}

.about-image-container > img{
    width: 60%;
}

.about-title{
    font-size: clamp(1rem, 1rem + 5vw, 7rem);
    color: var(--primary-gold);
    text-align: center;
    font-family: var(--ff-vibes);
    line-height: 1em;
}
.about-slogan{
    font-size: clamp(14px, .5rem + 2vw, 24px);
    color: var(--white);
    text-align: center;
}

.about-para{
    color: var(--white);
    line-height: 2em;
    font-size: clamp(12px, 14px, 16px);
}

.about-sub-heading{
    color: var(--primary-gold);
    font-family: var(--ff-vibes);
    text-align: center;
    font-size: clamp(1rem, 1rem + 3vw, 5rem);
    
}
@media screen and (min-width: 900px) {
    .about-sec{
        flex-direction: row;
    }
    .about-image-container, .about-content-container{
        width: 45%;
        margin-top: 0;
    }   
    .about-image-container > img{
        width: 80%;
    }
     
    .about-para{
        color: var(--white);
        line-height: 2em;
        font-size: clamp(12px, 1.2rem, 24px);
    }
}