.service-sec{
    height: 100vh;
    background-image: url('../../assets/images/webps/services_bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
}

.service-container{
    width: 90%;
    margin: 1em auto;
}

.service-title{
    font-size: clamp(1rem, 1rem + 7vw, 7rem);
    font-family: var(--ff-vibes);
    color: var(--primary-gold);

}
@media screen and (min-width: 900px) {
    .service-title{
        font-size: clamp(1rem, 1rem + 4vw, 7rem);
    }
}

.swiper-container{
    width: 90%;
    margin: 0 auto;
}
.service-slide{
    height: 470px;
    background: var(--white);
}


.event-title{
    text-align: center;
    padding: .5em 0;
    font-size: var(--fs-800);
    font-family: var(--ff-tail);
}
.service-image{
    width: 100%;
    height: 250px;
}
.event-para{
    width: 90%;
    font-size: clamp(12px, 14px, 16px);
    text-align: center;
    margin: 0 auto;
    line-height: 1.5em;
}

.swiper-button-next, .swiper-button-prev { color: var(--primary-navyblue); }


