/*------------------------------------------------------------------
[Master Stylesheet]

Project:    html-eventemporium
Version:    1.0
Created:  22/09/22
Author Name:  zygote info tech
Primary use:  html template
-------------------------------------------------------------------*/

:root {
  /* colors */
  --primary-navyblue: #0B1F38;
  --secondary-navyblue: #003b83;
  --primary-gold: #BF9559;
  --white: #ffffff;
  --textcolor: #404b69;
  --text-clr: #232323;
  --success: #5bc0de;
  --error: #d9534f;
  --warning: #f0ad4e;

  /* font-styles */

  --ff-vibes: "Great Vibes", cursive;
  --ff-roboto: "Roboto", sans-serif;
  --ff-tail: "Yellowtail", cursive;
  --ff-teko: "'Teko", snas-serif;

  /* font-sizes */
  --fs-900: 3rem;
  --fs-800: 2rem;
  --fs-700: 1.5rem;
  --fs-600: 1.3rem;
  --fs-500: 1rem;
  --fs-400: 0.9375rem;
  --fs-300: 1rem;
  --fs-200: 0.875rem;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
}

input,
button,
textarea,
select {
  font: inherit;
  outline: none;
}

img,
picutre {
  max-width: 100%;
  display: block;
}

li {
  list-style: none;
}

a{
  text-decoration: none;
  color: var(--text-clr);
}


html,body{
  overflow-x:hidden;
}

body {
  background: var(--white);
  color: var(--text-clr);
  font-family: var(--ff-roboto);
}

/* utility classes */

.btn{
  background: transparent;
  border: none;
  cursor: pointer;
}

.grid-center{
  display: grid;
  place-items: center;
}

.small{
  font-size: 10px;
}
.text-center{
  text-align: center;
}

.mt-1{
  margin-top: 15px;
}

.mt-3{
  margin-top: 20px;
}

.mt-4{
  margin-top: 30px;
}

.mt-5{
  margin-top: 40px;
}


.center{
  margin: 1em auto;
}

.primay-heading{
  font-size: 2.5rem;
  font-family: var(--ff-vibes);
  color: var(--primary-gold);
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: .5em;
  padding-right: .5em;
}

@media screen and (min-width: 900px) {
  .primay-heading{
    font-size: 68px;
    padding-top: 1em;
    padding-bottom: 0;
    padding-left: .5em;
    padding-right: .5em;
  }
}
.text-center{
  text-align: center;
}

.move-container{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: var(--primary-navyblue);
  z-index: -1;
  opacity: 1;
}
.common-sec{
  height: max-content;
  min-height: 100vh;
}

.common-container{
  width: 90%;
  margin: 1em auto;
}


.toast{
  position: fixed;
  top: -50%;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: 60px;
  border-radius: 4px;
  color: var(--white);
  z-index: 2;
}
@keyframes alertAnimator {
  0%{
    top: -50%;
  }30%, 40%, 50%, 60%, 70%, 80%, 90%{
    top: 0;
  }
  100%{
    top: -50%;
  }
}
.toast-success{
  background: var(--success);
  animation: alertAnimator 3s linear;
}
.toast-error{
  background: var(--error);
  animation: alertAnimator 3s linear;
}