.process-title{
    font-family: var(--ff-vibes);
    color: var(--primary-gold);
    padding: 0;
    margin: 0;
    font-size: 68px;
}
@media screen and (min-width: 900px) {
    .process-title{
        font-size: 80px;
    }
}
.process-container{
    width: 90%;
    margin: .5em auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}
.process-card{
    width: 90%;
    height: 250px;
    max-height: 250px;
}
@media screen and (min-width: 900px) {
    .process-container{
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    
    .process-card{
        width: 30%;
    }
}

.process-card img{
    margin: .5em auto;
    width: 100px;
    height: 100px;
}
.process-card h3{
    color: var(--primary-gold);
    padding: .3em 0;
    font-size: 16px;
}
.process-card p{
    color: var(--white);
    font-size: 12px;
    line-height: 2em;
}