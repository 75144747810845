.spe-service-sec{
    height: 120vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.spe-service-title{
    font-size: clamp(1rem, 1rem + 7vw, 7rem);
    color: var(--primary-gold);
    font-family: var(--ff-vibes);
    text-align: center;
}

.spe-service-slide{
    width: 100%;
    height: 570px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: var(--white);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.slide-img{
    width: 100%;
    height: 50%;
}
.slide-img > img{
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    
}
.border-img-container{
    min-height: max-content;
}

@media screen and (min-width: 900px) {
    .spe-service-sec{
        min-height: max-content;
    }
    .spe-service-title{
        font-size: clamp(1rem, 1rem + 4vw, 7rem);
    }
    .spe-service-slide{
        height: 490px;
        flex-direction: row;
    }
    .slide-img{
        width: 80%;
        height: 100%;
    }
    
    .slide-img > img{
        width: 100%;
        height: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
}

.slide-content{
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.slide-title{
    font-size: 38px;
    padding: 0 1em;
    text-align: center;
    color: var(--primary-gold);
    font-family: var(--ff-teko);
}
.slide-para{
    width: 90%;
    margin: 0 auto;
    text-align: center;
    font-size: var(--fs-500);
    line-height: 1.5em;
}
.slide-btn{
    width: 100px;
    margin: 0 auto;
    width: 134px;
    height: 55px;
    background: var(--primary-navyblue);
    border-radius: 10px;
    border: none;
    color: var(--white);
    cursor: pointer;
    transition: all .3s linear;
}
.slide-btn:hover{
    background: var(--white);
    border: 2px solid var(--primary-navyblue);
    color: var(--primary-navyblue);
}

@media screen and (min-width: 900px){
    
    .slide-title{
        font-size: 58px;
        padding: 0 1em;
        color: var(--primary-gold);
    }
    .slide-content{
        height: 100%;
    }
}


.swiper-button-next, .swiper-button-prev { color: var(--primary-gold); }


